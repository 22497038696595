.socialCard {
  aspect-ratio: 3.59;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: var(--Custom-bg0, linear-gradient(135deg, #0a0f2940 0%, #0a0f2921 40%, #9f01ad21 72%, #9f01ad40 100%), #030511);
  border: 2px solid #ffffff40;
  border-radius: 36px;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
  align-items: baseline;
  width: 92%;
  margin: 110px 1vw 0;
  padding: 60px 48px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 -4px 24px #0a0f2980, -5px 5px 24px #ffffff0d;
}

.socialCard img {
  content: url("RocketFooter.9623e6e1.webp");
  width: 800px;
  height: 800px;
  position: absolute;
  bottom: -28%;
  right: -8%;
}

.socialCard .social-button {
  aspect-ratio: 1;
  pointer-events: auto;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 80px;
  display: flex;
  overflow: hidden;
}

.socialCard .social-button svg {
  scale: 1.4;
}

.text-container {
  max-width: 60%;
  margin-top: -52px;
  padding-left: 4vw;
}

.socialCard h1 {
  color: #0086ff;
  font-family: var(--secondary-font);
  font-size: 48px;
  font-weight: 400;
  line-height: 1.2;
}

.socialCard p {
  color: #fff;
  font-family: var(--main-font);
  margin-top: 10px;
  font-size: 32px;
  line-height: 1.6;
}

.icons-container {
  pointer-events: auto;
  align-items: center;
  gap: 20px;
  padding-left: 4vw;
  display: flex;
}

.footer-base {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.FooterContainer {
  align-content: center;
  width: 100vw;
  height: 208px;
  position: relative;
}

footer {
  color: var(--text-color);
  text-align: center;
  background-color: var(--footer-bg-color);
  transform-origin: center;
  width: 80%;
  padding: 10px 0;
  font-family: Orbitron, sans-serif;
  font-size: .8em;
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
}

.left-section {
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  max-width: 20%;
  padding-right: 33%;
  display: flex;
}

.footer-logo {
  width: 236px;
}

.footer-description {
  color: var(--secondary-color);
  padding-top: 10px;
  font-size: 18px;
}

.menu-columns {
  justify-content: space-between;
  gap: 2rem;
  width: 75%;
  display: flex;
}

.menu-columns h4 {
  color: #fff;
  margin: 0;
  font-size: 18px;
}

.menu-column {
  text-align: left;
  flex-direction: column;
  display: flex;
}

.menu-column a {
  pointer-events: all;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.menu-list {
  padding: 0;
  list-style-type: none;
}

.menu-list li {
  margin-bottom: 25px;
}

.footer-link {
  color: #fff;
  pointer-events: auto;
  text-decoration: none;
  transition: color .3s;
}

.footer-link:hover {
  text-decoration: underline;
}

.bottom-part {
  text-align: center;
  margin: 0;
  padding: 0;
}

.bottom-part span {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding-bottom: 20px;
  display: flex;
}

.bottom-part img {
  width: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

.footer-divider {
  border: 1px solid #fff;
  margin-bottom: 1rem;
}

.bottom-part p {
  color: #fff;
  margin: 0;
}

.footer-privacy {
  gap: 80px;
  display: flex;
}

@media (width <= 1750px) {
  .socialCard {
    height: 425px;
  }
}

@media (width <= 1350px) {
  .socialCard h1 {
    font-size: 32px;
  }

  .socialCard p {
    font-size: 24px;
  }
}

@media (width <= 800px) {
  .socialCard {
    justify-content: flex-start;
    width: 90%;
    height: 600px;
    margin-bottom: 150px;
    padding: 0 1vw;
  }

  .text-container {
    max-width: 80%;
    margin-top: 0;
    padding-left: 10vw;
  }

  .socialCard h1 {
    font-size: 36px;
  }

  .socialCard p {
    font-size: 24px;
  }

  .socialCard img {
    width: 300px;
    height: 300px;
    bottom: -17%;
  }

  .icons-container {
    align-items: center;
    width: 54vw;
    padding-left: 10vw;
  }

  .footer-base {
    flex-direction: column;
    align-items: center;
  }

  .left-section {
    text-align: center;
    text-align: left;
    max-width: 100%;
    padding: 0;
  }

  .footer-logo {
    width: 250px;
    padding-bottom: 20px;
  }

  .menu-columns {
    width: 100%;
  }

  .footer-description {
    padding-bottom: 20px;
  }

  .bottom-part {
    justify-self: center;
    margin-top: 1rem;
  }

  .bottom-part p {
    padding-top: 80px;
  }

  .footer-privacy {
    text-align: left;
    flex-direction: column;
    gap: 10px;
    display: flex;
  }
}
/*# sourceMappingURL=index.05a241c5.css.map */
